<template>
  <div>
    <el-row>
      <el-form inline :model="checkManageCondition">
        <el-form-item label="编号/名称" class="formItemBoxStyle">
          <el-input
            style="width: 200px"
            v-model="checkManageCondition.taskCodeName"
            placeholder="请输入"
            @input="handleQueryTable"
          />
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            style="width: 200px"
            v-model="checkManageCondition.taskSubmitStatus"
            placeholder="请选择"
            @change="handleQueryTable"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="待提交" :value="1"></el-option>
            <el-option label="已提交" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleResetTable">重置</el-button>
          <el-button type="primary" @click="handleQueryTable">查询</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        v-loading="loading"
        :data="checkTaskData"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column label="任务编号" prop="taskCode"></el-table-column>
        <el-table-column label="任务名称" prop="taskName"></el-table-column>
        <el-table-column label="任务时间">
          <template slot-scope="scope">
            {{ handleDateFormate(scope.row.startTime) }}~{{
              handleDateFormate(scope.row.endTime)
            }}
          </template>
        </el-table-column>
        <el-table-column label="发布人">
          <template slot-scope="scope">
            {{ scope.row.publisherName ? scope.row.publisherName : "" }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scoped">
            <span>{{
              scoped.row.taskSubmitStatus == 1 ? "待提交" : "已提交"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scoped">
            <span
              @click="handleGoToCheckDetailPage(scoped.row)"
              style="color: #00f; cursor: pointer; text-decoration: underline"
              >{{ scoped.row.taskSubmitStatus == 1 ? "盘点" : "查看" }}</span
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <!-- 分页组件 -->
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeCheckManageTableMsg"
        @current-change="handleCurrentChangeCheckManageTableMsg"
        :current-page="checkManagePageInfo.pageIndex"
        :page-sizes="[10, 20, 50, 150, 200]"
        :page-size="checkManagePageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="checkManagePageInfo.pageTotal"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import { getCheckTaskListApi } from "@/api/materialManage/checkRecord.js";
import { formatDate } from "@/utils/util";
import { getStore } from "@/utils/token.js";
export default {
  name: "checkRecord",
  data() {
    return {
      activePage: "manage",
      checkManageCondition: {
        taskCodeName: "",
        taskSubmitStatus: 0,
        sysOrgSchoolId: 0,
      },
      checkTaskData: [],
      checkManagePageInfo: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      sysOrgSchoolId: 0,

      loading: true,
    };
  },
  created() {
    this.sysOrgSchoolId = Number(getStore("sysOrgSchoolId"));
    this.checkManageCondition.sysOrgSchoolId = this.sysOrgSchoolId;
    this.fetchCheckManageData();
  },
  methods: {
    fetchCheckManageData() {
      getCheckTaskListApi(
        this.checkManagePageInfo.pageIndex,
        this.checkManagePageInfo.pageSize,
        this.checkManageCondition
      ).then((res) => {
        if (res.success) {
          this.checkTaskData = res.data;
          this.checkManagePageInfo.pageTotal = res.total;
          this.loading = false
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    handleCurrentChangeCheckManageTableMsg(val) {
      this.checkManagePageInfo.pageIndex = val;
      this.fetchCheckManageData();
    },
    handleSizeChangeCheckManageTableMsg(val) {
      this.checkManagePageInfo.pageIndex = 1;
      this.checkManagePageInfo.pageSize = val;
      this.fetchCheckManageData();
    },
    // 跳转至详情页
    handleGoToCheckDetailPage(row) {
      this.$router.push({
        path: `/materialManage/checkRecordDetail?
        checkInfo=${JSON.stringify(row)}`,
      });
    },
    // 日期转换
    handleDateFormate(timestamp) {
      let time = new Date(timestamp);
      return formatDate(time, "yyyy/MM/dd");
    },
    // 查询
    handleQueryTable() {
      this.checkManagePageInfo.pageIndex = 1;
      this.checkManagePageInfo.pageSize = 10;
      this.fetchCheckManageData();
    },
    // 重置
    handleResetTable() {
      this.checkManageCondition = {
        taskCodeName: "",
        taskSubmitStatus: 0,
        sysOrgSchoolId: this.sysOrgSchoolId,
      };
      this.handleQueryTable();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tabsBox {
  .el-tabs__item {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
